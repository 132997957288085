
import { VPhoneInput } from './modified-script'
export default {
    components: {
        VPhoneInput,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        defaultCountry: {
            type: String,
            default: 'TH',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '',
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        countryLabel: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        filled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hasValidatedPhone: false,
        }
    },
    computed: {
        phoneModel: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        hideDetailProps() {
            if (this.hideDetails)
                return {
                    hideDetails: 'auto',
                }
            return {}
        },
        inValidMessageText() {
            if (!this.phoneModel || !this.hasValidatedPhone) return null
            return this.tl('phoneNumberFormatInvalid')
        },
    },
    watch: {
        phoneModel(value) {
            const phoneInput = this.$refs.phoneInput
            if (phoneInput) {
                const isValid = phoneInput.validate()
                this.$emit('validate', isValid, value)
            }
        },
    },
    methods: {
        onBlur() {
            if (this.phoneModel) {
                this.hasValidatedPhone = true
            }
        },
    },
}
